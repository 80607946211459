import zh from './cn.json'
import en from './en.json'

export default {
  cn: {
    ...zh
  },
  en: {
    ...en
  }
}