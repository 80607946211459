<template>
  <div class="home">
    <div class="box-abs">
      <img
        src="./img/home/line3.png"
        alt=""
        class="line3"
      >
    </div>
    <div class="box-top">
      <div class="box-content box-content0">
        <div class="info">
          <div class="box-nav flex-between">
            <img
              class="logo"
              src="./img/home/logo-pc.png"
              alt=""
            >
            <div class="flex box-right">
              <div>
                <a :href=" isChineseUser ? `#` : `https://app.nexisdao.com`" :target="isChineseUser ? `_self` : `_blank`" style="color: #111111;text-decoration:none">NexisDAO</a>
              </div>
              <div class="txt2">
                <a :href=" isChineseUser ? `#` : `https://app.nexisdao.com/dckb`" :target="isChineseUser ? `_self` : `_blank`" style="color: #111111;text-decoration:none">dCKB</a>
              </div>
              <div class="txt3">
                <a :href=" isChineseUser ? `#` : `https://docs.nexisdao.com/`" :target="isChineseUser ? `_self` : `_blank`" style="color: #111111;text-decoration:none">Docs</a>
              </div>
              <div class="txt4">
                <a :href=" isChineseUser ? `#` : `https://github.com/NexisDao/NexisDao-core`" :target="isChineseUser ? `_self` : `_blank`"  style="color: #111111;text-decoration:none">Github</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-content box-content01">
        <topNav :isChineseUser="isChineseUser"></topNav>
      </div>
      <div class="box-content box-content2">

        <div class="info">
          <img
            src="./img/home/sanjiao.png"
            alt=""
            class="sanjiao"
          >
          <div class="box-txt">
            {{$t('A smarter, more efficient and transparent stable coin protocol on Nervos.')}}
          </div>
          <a class="btn-get flex-center" :target="isChineseUser ? `_self` : `_blank`" style="text-decoration:none" :href=" isChineseUser ? `#` : `https://app.nexisdao.com`" >
            {{$t('Get TAI')}}
          </a>
          <img
            src="./img/home/shaizi.png"
            alt=""
            class="shaizi"
          >
          <img
            src="./img/mobile/shaizi2.png"
            alt=""
            class="shaizi2"
          >
          <img
            class="zhengfangx"
            src="./img/mobile/bg-zhengfaing.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="box-content box-content3">
      <div class="info flex-between">
        <div class="bg-left">
          <img
            src="./img/home/bg-left.png"
            alt=""
            class="bg-left-img"
          >
        </div>
        <div class="box-right2">
          <div class="box-title">
            <span class="big">Nexis </span>
            <span class="small"> {{$t('A decentralized autonomous organization')}} </span>
          </div>
          <div class="content">
            <div>
              {{$t('Nexis Protocol is a smart contracts portfolio on Nervos that allows anyone to deposit collateral assets to generate Tai whenever and wherever. The Nexis Protocol supports and stabilizes the value of Tai through its automatic self-feedback mechanism and proper external incentives.')}}
            </div>
            <div class="info2">
              {{$t('The Nexis Protocol is supported by smart contracts and algorithms to ensure its decentralization, and it ensures its fairness and autonomy through a system of scientific governance involving Executive Voting and Governance Polling.')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-content box-content4">
      <div class="info flex-between">
        <div class="box-left">
          <div class="box-title">
            <span class="big">Tai </span>
            <span class="small"> {{$t('A decentralised cryptocurrency')}} </span>
          </div>
          <div class="content">
            <div> {{$t('Backed by excess collateral on-chain, Tai is soft-pegged to US Dollar, i.e. 1 Tai = 1 USD.')}}
            </div>
            <div class="info2"> {{$t('Any individuals and organizations are capable of swapping Tai for the assets served as a store of value or leveraging Tai as collateral to obtain liquidity. Tai can also be used as a medium of exchange that represents a standard of value and is used to facilitate the sale, purchase, or exchange (trade) of goods or services. ')}}</div>
          </div>
        </div>
        <div class="box-right">
          <img
            src="./img/home/bg-right.png"
            alt=""
            class="img"
          >
        </div>
      </div>
    </div>
    <div class="box-content box-conten5">
      <div class="info">
        <img
          src="./img/home/bg-5-zhengfangxing.png"
          alt=""
          class="bg-5-zhengfang"
        >
        <img
          src="./img/home/bg-5-2.png"
          alt=""
          class="bg-5-2"
        >
        <div class="flex-center title">What we offer</div>
        <div class="flex-between box-items">
          <div class="box-item">
            <img
              src="./img/home/icon1.png"
              alt=""
              class="icon"
            >
            <div class="title-small">
              {{$t("Stability")}}
            </div>
            <div class="word7">
              {{$t("NexisDAO adopts self-adjusted interest rate to control the supply of Tai, encouraging a dynamic systematic debt position to balance the value of Tai.")}}
            </div>
          </div>
          <div class="box-item">
            <img
              src="./img/home/icon2.png"
              alt=""
              class="icon"
            >
            <div class="title-small">
              {{$t("Decentralization")}}
            </div>
            <div class="word7">
              {{$t("NexisDAO is supported by smart contracts and algorithms to ensure its decentralization, and it ensures its fairness and autonomy through a system of scientific governance involving Executive Voting and Governance Polling.")}}
            </div>
          </div>
          <div class="box-item">
            <img
              src="./img/home/icon3.png"
              alt=""
              class="icon"
            >
            <div class="title-small">
              {{$t("Ecology")}}
            </div>
            <div class="word7">
              {{$t("It is foreseeable that Tai will provide multiple opportunities for crypto market participants and become the perfect means of bridging the whole crypto ecosystem.")}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-content box-conten6">
      <div class="info flex-between">
        <img
          class="logo"
          src="./img/home/logo.png"
          alt=""
        >
        <div style="width:100%;justify-content: space-evenly;padding-top:30px;" class="mobile-icon">
          <a href="https://t.me/NexisDaoProtocol" target="_blank" style="color:#222228;"><i class="fab fa-telegram-plane"></i></a>
          <a href="https://discord.gg/vE4dwP3Wgg" style="color:#222228;" target="_blank"><i class="fab fa-discord"></i></a>
          <a href="https://twitter.com/NexisDAO" style="color:#222228;" target="_blank"><i class="fab fa-twitter"></i></a>
        </div>

          <a href="https://t.me/NexisDaoProtocol" target="_blank" style="color:#222228;text-decoration:none" class="web-icon"><i class="fab fa-telegram-plane"></i></a>
          <a href="https://discord.gg/vE4dwP3Wgg" style="color:#222228;text-decoration:none" target="_blank" class="web-icon"><i class="fab fa-discord"></i></a>
          <a href="https://twitter.com/NexisDAO" style="color:#222228;text-decoration:none" target="_blank" class="web-icon"><i class="fab fa-twitter"></i></a>

        <!-- <div class=" box-eng"> -->
          <div class="flex-center box-en-icon" style="color: rgba(34, 34, 40, 1);font-size: 14px;line-height: 25px;">
            <img
              src="./img/home/en.png"
              alt=""
              style="width: 15px;height: 15px;"
            >
            <div @click="changeLang()"> {{this.$i18n.locale === 'cn'? `English`: `中文`}}</div>
          </div>
          <div style="color: rgba(34, 34, 40, 1);font-size: 14px;line-height: 25px;">
            © 2021. All rights reserved.
          </div>
        <!-- </div> -->
      </div>
    </div>
    <div class="box-notice" v-if="isChineseUser" v-show="showNotice">
      <p class="notice-title" style="padding-top:20px">{{$t('Reminder')}}</p>
      <p class="notice-content" style="padding-top:20px">{{$t('NoticeContent')}}</p>
      <div class="notice-button">
        <div>
          <button class="notice-yes" @click.prevent="setIsChineseUser">{{$t('YES')}}</button>
        </div>
        <div>
          <button class="notice-no" @click.prevent="setNotChineseUser">{{$t('NO')}}</button>
        </div>
      </div>
    </div>
    <div class="box-notice-disabled" v-if="isChineseUser" v-show="showBanned">
      <p class="notice-title-disabled" style="padding-top:20px">{{$t('Reminder')}}</p>
      <p class="notice-content-disabled">{{$t('BannnedContent')}}</p>
    </div>
  </div>
</template>

<script>
import topNav from "@/components/top-nav";
export default {
  name: "Home",
  components: {
    topNav,
  },
  data(){
    return {
      isChineseUser: (this.$cookies.get("isChineseUser")) === "false" ? false : true,
      showNotice: true,
      showBanned:false
    }
  },
  methods:{
    changeLang(){
      if ( this.$i18n.locale === 'cn' ) {
        this.$i18n.locale = 'en';
      }else {
        this.$i18n.locale = 'cn';
      }
      // chrome
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0

    },
    setIsChineseUser(){
      this.$cookies.set("isChineseUser",true, "15d")
      this.showNotice = false;
      this.showBanned = true;
    },
    setNotChineseUser(){
      this.$cookies.set("isChineseUser",false, "15d")
      this.isChineseUser = false;
      this.showNotice = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./index.scss";

.box-notice{
  width: 807px;
  height: 420px;
  z-index: 9998;
  position: absolute;
  top: calc(50vh - 120px);
  left:calc(50vw - 400px);
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.03);
  border-radius: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notice-title{
  width: 100px;
  height: 69px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 69px;
  text-align: center;
}
.notice-content{
  width: 707px;
  height: 96px;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  // line-height: 32px;
  display: flex;
}
.notice-button{
  display:flex; 
  flex-direction: row;
  justify-content: 
  space-evenly;
  width:100%;
  padding-top:20px
}

.notice-yes{
  width: 159px;
  height: 52px;
  background: #FFFFFF;
  box-shadow: 6px 7px 14px 1px rgba(120, 218, 204, 0.37);
  border-radius: 26px;
  color: #76CED2;
  border: 2px solid rgba(118, 206, 210, 0.54);
}
.notice-no{
  width: 159px;
  height: 52px;
  background: #76CED2;
  box-shadow: 6px 7px 14px 1px rgba(120, 218, 204, 0.37);
  border-radius: 26px;
  color: #FFFFFF;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.box-notice-disabled{
  top: calc(50vh - 120px);
  left:calc(50vw - 300px);
  z-index: 9998;
  width: 599px;
  height: 300px;
  position: absolute;
  background: rgba(20, 20, 20, 0.85);
  box-shadow: 0px 12px 14px 0px rgba(0, 0, 0, 0.03);
  border-radius: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notice-title-disabled{
  width: 100px;
  height: 69px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 69px;
  color: #ffffff;
  text-align: center;
}
.mobile-icon{
  display: none;
}
.web-icon{
  display: inherit;
}
.notice-content-disabled{
  width: 400px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 32px;
}
@media screen and (max-width: 500px) {
  .box-notice{
    width: 330px;
    height: 500px;
    top: 120px;
    left:calc((100vw - 330px)/2);
  }
  .mobile-icon{
    display: inherit;
  }
  .web-icon{
    display: none;
  }
  .notice-content{
    width: 80%;
  }
  .notice-button{
    margin-top: 50px;
    display:flex; 
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height: 140px;
    align-items: center;
    padding-top:20px
  }

  .box-notice-disabled{
    width: 330px;
    height: 260px;
    top: 220px;
    left:calc((100vw - 330px)/2);
  }
  .notice-content-disabled{
    width: 250px;
  }

}

</style>
