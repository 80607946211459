<template>
  <div class="box-fath">
    <div
      class="box-menu"
      :class="{showFixed:show}"
    >
      <div class="box-nav flex-between">
        <img
          class="logo"
          src="./logo2.png"
          alt=""
        >
        <img
          src="./menu.png"
          alt=""
          class="menu"
          v-show="!show"
          @click="show=!show"
        >
        <img
          src="./close.png"
          alt=""
          class="menu"
          v-show="show"
          @click="show=!show"
        >
      </div>

      <div
        v-show="show"
        class="box-items"
      >
        <div class="box-item-txts">
          <div class="zero">
            <a :href=" isChineseUser ? `#` : `https://app.nexisdao.com`" :target="isChineseUser ? `_self` : `_blank`" style="color: #111111;text-decoration:none">NexisDAO</a>
          </div>
          <div>                
            <a :href=" isChineseUser ? `#` : `https://app.nexisdao.com/dckb`" :target="isChineseUser ? `_self` : `_blank`" style="color: #111111;text-decoration:none">dCKB</a>
          </div>
          <div><a :href=" isChineseUser ? `#` : `https://docs.nexisdao.com/`" :target="isChineseUser ? `_self` : `_blank`" style="color: #111111;text-decoration:none">Docs</a></div>
          <div><a :href=" isChineseUser ? `#` : `https://github.com/NexisDao/NexisDao-core`" :target="isChineseUser ? `_self` : `_blank`"  style="color: #111111;text-decoration:none">Github</a></div>
        </div>
        <div class="flex-center box-en0">
          <div class="box-en flex-center">
            <img
              src="./en.png"
              alt=""
              class="en"
            >
            <span @click="changeLang()"> {{this.$i18n.locale === 'cn'? `English`: `中文`}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false
    };
  },
  props:{
    isChineseUser:{
      type:Boolean,
      default: true
    }
  },
  methods:{
    changeLang(){
      if ( this.$i18n.locale === 'cn' ) {
        this.$i18n.locale = 'en';
      }else {
        this.$i18n.locale = 'cn';
      }
      this.show = false
    }
  }
};
</script>
<style lang="scss" scoped>
.box-nav {
  height: 47px;
  border-radius: 14px;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 3%);
  background: url(./bg-nav.png) -14px -4px no-repeat;
  width: 330px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  .logo {
    width: 107px;
    height: 16px;
  }
  .menu {
    width: 17px;
    height: 16px;
  }
}
.showFixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  // background: url(./bg-nav2.png) 0 0 no-repeat;
  background-size: 100% auto;
  background-color: rgba(249, 250, 248,0.9);
  padding-top: 48px;
}
.box-items {
  z-index: 228;
  height: 441px;
  margin: 0 auto;
  border-radius: 14px;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 3%);
  background: url(./bg-items.png) -14px -4px no-repeat;
  width: 330px;
  padding-bottom: 23px;
  align-items: center;
  padding-top: 43px;
  margin-top: 16px;
  .box-item-txts > div {
    z-index: 235;
    display: block;
    overflow-wrap: break-word;
    color: rgba(17, 17, 17, 1);
    font-size: 22px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 30px;
    text-align: center;
    margin-top: 49px;
  }
  .zero {
    margin-top: 0 !important;
  }
  .box-en0 {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 53px;
  }
  .box-en {
    width: 106px;
    padding-top: 27px;
    .en {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
    overflow-wrap: break-word;
    color: rgba(34, 34, 40, 1);
    font-size: 14px;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }
}
</style>